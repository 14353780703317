<!-- 钱包提现 -->
<template>
	<div :class="themeClass">
		<div class="margin-t-20">
			<div class="font-size18 font-color-333 font-weight700 margin-t-15">
				钱包提现
			</div>
			<div class="margin-t-6 recharge-wallet-box border-F2F2F2">
				<div class="recharge-amount-box flex-row-align-center">
					<div class="font-size14 font-color-333 recharge-amount-text">提现金额:</div>
					<div class="font-size38 font-color-000">
						￥
					</div>
					<el-input ref="amount" :autofocus="true" v-model="amount" @input="setOnlyMoney" :maxlength="moneyMaxLeng"></el-input>
					<div class="color-theme font-size13 margin-l-3 select-recharge-button" @click="selectRecharge">选择充值单 <span>></span></div>
				</div>
				<div class="flex-row margin-t-10">
					<div class="tips-box flex-row-align-center font-size14 border-bottom-F2F2F2 margin-r-15">
						<div class="margin-r-10 font-color-999">账户余额:</div>
						<div class="margin-r-15 font-color-FF9900">{{balance}}元</div>
						<div class="all-withdrawal color-theme" @click="allWithdrawal">全部提现</div>
					</div>
					<div class="font-size13 font-color-FF9900">
						*温馨提示：输入金额,点击“选择充值单”，系统将匹配可提现的充值单
					</div>
				</div>
				<div v-if="isSelect">
					<div class="margin-t-20 font-size14 font-weight700 font-color-333">
						已选择充值单
					</div>
					<div class="margin-t-10">
						<div class="flex-row-space-between-center choose-recharge-list-top-box">
							<div class="flex-colum font-size14">
								<div class="font-weight700 font-color-000">
									充值 ¥5000.00
								</div>
								<div class="font-color-333 margin-t-6">
									日期：2020-04-27
								</div>
							</div>
							<div class="color-theme">
								可退金额 ¥200.56
							</div>
						</div>
						<div class="choose-recharge-list-bottom-box flex-colum font-size13 font-color-666">
							<div>
								退款账户：CFCA
							</div>
							<div class="margin-t-2">
								退款账号：955508656220565656
							</div>
							<div class="margin-t-2">
								开户行：北京市宣武区菜市口支行
							</div>
						</div>
					</div>
				</div>
				<div class="recharge-button font-size14 background-color-theme border-color-theme pointer flex-row-center-center" @click="changePayment">立即提现</div>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		data() {
			return {
				amount: '', //金额
				balance: '8800',
				moneyMaxLeng: 9, //金额输入框最大长度
				isSelect: false
			};
		},
		mounted() {
			this.$refs.amount.focus();
		},
		created() {
			if (this.$route.query.isSelect) {
				this.isSelect = this.$route.query.isSelect;
			}
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		methods: {
			//输入金额限制
			setOnlyMoney() {
				this.$nextTick(() => {
					let val = this.amount.toString();
					val = val.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
					val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
					val = val.replace(/^0+\./g, '0.');
					val = val.match(/^0+[1-9]+/) ? val = val.replace(/^0+/g, '') : val
					val = (val.match(/^\d*(\.?\d{0,2})/g)[0]) || ''
					if (val.includes(".")) {
						let numDian = val.toString().split(".")[1].length;
						if (numDian === 2) {
							this.moneyMaxLeng = val.length;
						}
					} else {
						this.moneyMaxLeng = 9;
					}
					this.amount = val;
				});
			},
			//选择充值单
			selectRecharge() {
				this.$router.push({
					path: '/businessme/SelectRechargeList',
					query: ''
				});
			},
			//全部提现
			allWithdrawal() {
				this.amount = this.balance;
			},
			changePayment() {
				if (!this.amount) {
					this.$message('请输入提现金额');
					return
				}
				console.log("提现");
			},

		}

	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.recharge-wallet-box {
		padding: 20px 0 0 20px;
		width: 897px;
		height: 402px;
	}

	.select-recharge-button:hover {
		cursor: pointer;
	}

	.all-withdrawal:hover {
		cursor: pointer;
	}

	.recharge-amount-box {
		height: 44px;
		width: 390px;
	}

	.recharge-amount-text {
		width: 70px;
	}

	.tips-box {
		height: 24px;
		padding-bottom: 2px;
		width: 391px;
	}

	.recharge-button {
		margin: 40px 0 0 0px;
		width: 235px;
		height: 45px;
		border-radius: 0px;
	}

	.choose-recharge-list-top-box {
		width: 351px;
		height: 65px;
		padding: 0 20px;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		background-image: linear-gradient(to right, #F0F3FF, #DCE3FE);
	}

	.choose-recharge-list-bottom-box {
		width: 351px;
		height: 56px;
		background-color: #f8f9fe;
		padding: 10px 20px;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
	}

	/deep/.el-input {
		width: 180px;
	}

	/deep/.el-input__inner {
		width: 180px;
		height: 44px !important;
		font-size: 34px;
		color: #000000;
		padding: 0;
		border: none !important;
		background-color: rgba(255, 255, 255, 0);
	}
</style>
